import React, { useState, useRef, useEffect } from 'react';

import './App.css';

const Alert = ({ children, type = 'info' }) => (
  <div className={`p-4 mb-4 rounded ${type === 'error' ? 'bg-red-100 text-red-700' : 'bg-blue-100 text-blue-700'}`}>
    {children}
  </div>
);
const GitVisualizer = () => {
  const [commits, setCommits] = useState([]);
  const [branches, setBranches] = useState(['main']);
  const [currentBranch, setCurrentBranch] = useState('main');
  const [command, setCommand] = useState('');
  const [message, setMessage] = useState('');
  const [HEAD, setHEAD] = useState(0);
  const [commandHistory, setCommandHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);

  const gitCommands = ['commit', 'branch', 'checkout', 'merge', 'reset', 'rebase', 'cherry-pick', 'stash'];

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleCommand = () => {
    if (command.trim()) {
      setCommandHistory([...commandHistory, command]);
      setHistoryIndex(-1);
    }

    const parts = command.trim().split(' ');
    const mainCommand = parts[0]?.toLowerCase() || '';

    switch (mainCommand) {
      case 'git':
        handleGitCommand(parts.slice(1));
        break;
      case 'undo':
        handleUndo();
        break;
      case 'redo':
        handleRedo();
        break;
      default:
        setMessage(`Unknown command: ${command}`);
    }

    setCommand('');
    setSuggestions([]);
  };

  const handleGitCommand = (args) => {
    const subCommand = args[0]?.toLowerCase() || '';

    switch (subCommand) {
      case 'commit':
        if (args[1] === '-m' && args[2]) {
          const newCommit = {
            id: commits.length + 1,
            message: args[2],
            branch: currentBranch,
            parent: HEAD
          };
          setCommits([...commits, newCommit]);
          setHEAD(newCommit.id);
          setMessage(`Created new commit: ${newCommit.id}`);
        } else {
          setMessage('Invalid commit command. Use "git commit -m <message>"');
        }
        break;
      case 'branch':
        if (args[1] && !branches.includes(args[1])) {
          setBranches([...branches, args[1]]);
          setMessage(`Created new branch: ${args[1]}`);
        } else {
          setMessage('Invalid branch name or branch already exists');
        }
        break;
      case 'checkout':
        if (branches.includes(args[1])) {
          setCurrentBranch(args[1]);
          const lastCommitOnBranch = [...commits].reverse().find(c => c.branch === args[1]);
          setHEAD(lastCommitOnBranch ? lastCommitOnBranch.id : 0);
          setMessage(`Switched to branch: ${args[1]}`);
        } else {
          setMessage(`Branch not found: ${args[1]}`);
        }
        break;
      case 'merge':
        if (args[1] && branches.includes(args[1])) {
          const sourceLastCommit = [...commits].reverse().find(c => c.branch === args[1]);
          const targetLastCommit = [...commits].reverse().find(c => c.branch === currentBranch);
          if (sourceLastCommit && targetLastCommit) {
            const mergeCommit = {
              id: commits.length + 1,
              message: `Merge branch '${args[1]}' into ${currentBranch}`,
              branch: currentBranch,
              parent: targetLastCommit.id,
              mergeParent: sourceLastCommit.id
            };
            setCommits([...commits, mergeCommit]);
            setHEAD(mergeCommit.id);
            setMessage(`Merged ${args[1]} into ${currentBranch}`);
          } else {
            setMessage('Nothing to merge');
          }
        } else {
          setMessage(`Invalid branch for merge: ${args[1]}`);
        }
        break;
      case 'reset':
        if (args[1] === '--hard' && args[2]) {
          const targetCommit = commits.find(c => c.id === parseInt(args[2]));
          if (targetCommit) {
            setHEAD(targetCommit.id);
            setCommits(commits.filter(c => c.id <= targetCommit.id));
            setMessage(`Reset to commit ${targetCommit.id}`);
          } else {
            setMessage(`Commit not found: ${args[2]}`);
          }
        } else {
          setMessage('Invalid reset command. Use "git reset --hard <commit-id>"');
        }
        break;
      case 'rebase':
        if (args[1] && branches.includes(args[1])) {
          const sourceLastCommit = [...commits].reverse().find(c => c.branch === currentBranch);
          const targetLastCommit = [...commits].reverse().find(c => c.branch === args[1]);
          if (sourceLastCommit && targetLastCommit) {
            const rebasedCommits = commits
              .filter(c => c.branch === currentBranch)
              .map(c => ({ ...c, parent: targetLastCommit.id, id: commits.length + 1 }));
            setCommits([...commits, ...rebasedCommits]);
            setHEAD(rebasedCommits[rebasedCommits.length - 1].id);
            setMessage(`Rebased ${currentBranch} onto ${args[1]}`);
          } else {
            setMessage('Nothing to rebase');
          }
        } else {
          setMessage(`Invalid branch for rebase: ${args[1]}`);
        }
        break;
      case 'cherry-pick':
        if (args[1]) {
          const pickedCommit = commits.find(c => c.id === parseInt(args[1]));
          if (pickedCommit) {
            const newCommit = {
              ...pickedCommit,
              id: commits.length + 1,
              branch: currentBranch,
              parent: HEAD,
              message: `Cherry-pick: ${pickedCommit.message}`
            };
            setCommits([...commits, newCommit]);
            setHEAD(newCommit.id);
            setMessage(`Cherry-picked commit ${args[1]}`);
          } else {
            setMessage(`Commit not found: ${args[1]}`);
          }
        } else {
          setMessage('Invalid cherry-pick command. Use "git cherry-pick <commit-id>"');
        }
        break;
      case 'stash':
        if (args[1] === 'apply') {
          setMessage('Stash applied (simulated)');
        } else {
          setMessage('Changes stashed (simulated)');
        }
        break;
      default:
        setMessage(`Unknown Git command: ${subCommand}`);
    }
  };

  const handleUndo = () => {
    if (commits.length > 0) {
      const newCommits = commits.slice(0, -1);
      setCommits(newCommits);
      setHEAD(newCommits.length > 0 ? newCommits[newCommits.length - 1].id : 0);
      setMessage('Undo: Reverted last action');
    } else {
      setMessage('Nothing to undo');
    }
  };

  const handleRedo = () => {
    // For simplicity, we're not implementing actual redo functionality
    setMessage('Redo functionality not implemented in this demo');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleCommand();
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      if (historyIndex < commandHistory.length - 1) {
        setHistoryIndex(historyIndex + 1);
        setCommand(commandHistory[commandHistory.length - 1 - historyIndex - 1]);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (historyIndex > -1) {
        setHistoryIndex(historyIndex - 1);
        setCommand(historyIndex === 0 ? '' : commandHistory[commandHistory.length - 1 - historyIndex + 1]);
      }
    } else if (e.key === 'Tab') {
      e.preventDefault();
      if (suggestions.length > 0) {
        setCommand(suggestions[0]);
        setSuggestions([]);
      }
    }
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setCommand(input);

    if (input.startsWith('git ')) {
      const partialCommand = input.slice(4);
      const matchedCommands = gitCommands.filter(cmd => cmd.startsWith(partialCommand));
      setSuggestions(matchedCommands.map(cmd => `git ${cmd}`));
    } else {
      setSuggestions([]);
    }
  };

  const renderGraph = () => {
    const nodeSize = 30;
    const horizontalSpacing = 80;
    const verticalSpacing = 50;
    const width = commits.length * horizontalSpacing + 100;
    const height = branches.length * verticalSpacing + 100;

    const getCommitCoordinates = (commit) => {
      const x = commit.id * horizontalSpacing;
      const y = (branches.indexOf(commit.branch) + 1) * verticalSpacing;
      return { x, y };
    };

    return (
      <svg width={width} height={height}>
        {commits.map((commit) => {
          const { x, y } = getCommitCoordinates(commit);
          return (
            <g key={commit.id}>
              <circle
                cx={x}
                cy={y}
                r={nodeSize / 2}
                fill={commit.id === HEAD ? 'red' : 'blue'}
              />
              <text x={x} y={y} textAnchor="middle" dy=".3em" fill="white">
                {commit.id}
              </text>
              {commit.parent && (
                <line
                  x1={x}
                  y1={y}
                  x2={commit.parent * horizontalSpacing}
                  y2={getCommitCoordinates(commits[commit.parent - 1]).y}
                  stroke="black"
                />
              )}
              {commit.mergeParent && (
                <line
                  x1={x}
                  y1={y}
                  x2={commit.mergeParent * horizontalSpacing}
                  y2={getCommitCoordinates(commits[commit.mergeParent - 1]).y}
                  stroke="green"
                  strokeDasharray="5,5"
                />
              )}
              <title>{commit.message}</title>
            </g>
          );
        })}
        {branches.map((branch, index) => (
          <text
            key={branch}
            x={10}
            y={(index + 1) * verticalSpacing}
            fill={branch === currentBranch ? 'red' : 'black'}
          >
            {branch}
          </text>
        ))}
      </svg>
    );
  };

  return (
    <div className="p-4 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">Interactive Git Visualizer</h1>
      <div className="mb-4 relative">
        <input
          ref={inputRef}
          type="text"
          value={command}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Enter Git command"
          className="w-full p-2 border rounded"
        />
        {suggestions.length > 0 && (
          <ul className="absolute z-10 bg-white border border-gray-300 w-full">
            {suggestions.map((suggestion, index) => (
              <li key={index} className="p-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => { setCommand(suggestion); setSuggestions([]); }}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}
        <button onClick={handleCommand} className="mt-2 px-4 py-2 bg-blue-500 text-white rounded">
          Execute
        </button>
      </div>
      {message && (
        <Alert type={(message.toLowerCase?.().includes('error') ? 'error' : 'info')}>
          {message}
        </Alert>
      )}
      <div className="mb-4">
        <h2 className="text-xl font-semibold mb-2">Repository State</h2>
        <p>Current Branch: {currentBranch}</p>
        <p>Branches: {branches.join(', ')}</p>
        <p>HEAD: Commit {HEAD}</p>
      </div>
      <div className="mb-4 overflow-x-auto">
        <h2 className="text-xl font-semibold mb-2">Commit Graph</h2>
        {renderGraph()}
      </div>
      <div>
        <h2 className="text-xl font-semibold mb-2">Commit History</h2>
        <ul className="list-disc pl-5">
          {commits.map((commit) => (
            <li key={commit.id} className="mb-2">
              <strong>Commit {commit.id}</strong> ({commit.branch}): {commit.message}
              {commit.mergeParent && ` (Merge commit, parents: ${commit.parent}, ${commit.mergeParent})`}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GitVisualizer;

